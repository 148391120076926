import SwitchInput from "@/components/SwitchInput.vue";
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    confirmationOnTurnOff: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: this.value
    };
  },
  methods: {
    willChange(data) {
      this.$emit("willChange", data);
    },
    confirmChangeCheck(val) {
      if (this.confirmationOnTurnOff && this.data && !val) {
        this.$emit("willChange", {
          value: this.data,
          confirm: () => {
            this.confirmChange(val);
          }
        });
      } else {
        this.confirmChange(val);
      }
    },
    confirmChange(val) {
      this.data = val;
    }
  },
  watch: {
    value(val, oldVal) {
      if (oldVal !== val) {
        this.data = val;
      }
    },
    data(val, oldVal) {
      if (oldVal !== val) {
        this.$emit("input", val);
      }
    }
  },
  components: {
    SwitchInput
  }
};
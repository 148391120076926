export default {
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      data: this.value
    };
  },
  methods: {
    change(index) {
      this.data[index].value = !this.data[index].value;
      this.$emit("input", this.data);
    }
  },
  watch: {
    value(val) {
      this.data = val;
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('has-tokens', [!_vm.loading ? _c('report-page', [_c('main-title', [_vm._v(" Создание сценария ")]), _c('div', {
    staticClass: "new-script__step"
  }, [_c('h3', {
    staticClass: "new-script__step__title"
  }, [_vm._v("Настройки скрипта")]), _c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("Название скрипта")])]), _c('div', {
    staticClass: "settings__block__content"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Например, Ответ на хорошие отзывы"
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.name = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("Выбрать магазин")])]), _c('div', {
    staticClass: "settings__block__content"
  }, [_c('app-select', {
    staticClass: "tokens__select",
    attrs: {
      "items": _vm.select_tokens_items
    },
    model: {
      value: _vm.token,
      callback: function ($$v) {
        _vm.token = $$v;
      },
      expression: "token"
    }
  })], 1)])]), _c('div', {
    staticClass: "new-script__step"
  }, [_c('h3', {
    staticClass: "new-script__step__title"
  }, [_vm._v("Опции применения")]), _c('div', {
    staticClass: "new-script__step__options"
  }, [_c('div', {
    staticClass: "new-script__step__options__item"
  }, [_c('div', {
    staticClass: "new-script__step__line"
  }, [_c('span', {
    staticClass: "new-script__step__key"
  }, [_vm._v(" Использовать для отзывов с оценкой ")]), _c('span', {
    staticClass: "new-script__step__value"
  }, [_c('MultiCheckbox', {
    model: {
      value: _vm.marks,
      callback: function ($$v) {
        _vm.marks = $$v;
      },
      expression: "marks"
    }
  })], 1)]), _c('div', {
    staticClass: "new-script__step__line"
  }, [_c('span', {
    staticClass: "new-script__step__key"
  }, [_vm._v(" Отвечать на старые отзывы ")]), _c('span', {
    staticClass: "new-script__step__value"
  }, [_c('YesNoSwitchInput', {
    model: {
      value: _vm.answerOldReviews,
      callback: function ($$v) {
        _vm.answerOldReviews = $$v;
      },
      expression: "answerOldReviews"
    }
  })], 1)]), _c('div', {
    staticClass: "new-script__step__line"
  }, [_c('span', {
    staticClass: "new-script__step__key"
  }, [_vm._v(" Ручная проверка ")]), _c('span', {
    staticClass: "new-script__step__value"
  }, [_c('YesNoSwitchInput', {
    attrs: {
      "confirmationOnTurnOff": true
    },
    on: {
      "willChange": _vm.checkingByHandWillChange
    },
    model: {
      value: _vm.checkingByHand,
      callback: function ($$v) {
        _vm.checkingByHand = $$v;
      },
      expression: "checkingByHand"
    }
  })], 1)]), _c('div', {
    staticClass: "new-script__step__line"
  }, [_c('span', {
    staticClass: "new-script__step__key"
  }, [_vm._v(" Применять только для отзывов с фото ")]), _c('span', {
    staticClass: "new-script__step__value"
  }, [_c('YesNoSwitchInput', {
    model: {
      value: _vm.onlyForReviewsWithPhoto,
      callback: function ($$v) {
        _vm.onlyForReviewsWithPhoto = $$v;
      },
      expression: "onlyForReviewsWithPhoto"
    }
  })], 1)]), _c('div', {
    staticClass: "new-script__step__line"
  }, [_c('span', {
    staticClass: "new-script__step__key"
  }, [_vm._v(" Применять для конкретных товаров ")]), _c('span', {
    staticClass: "new-script__step__value"
  }, [_c('YesNoSwitchInput', {
    model: {
      value: _vm.forSomeProducts,
      callback: function ($$v) {
        _vm.forSomeProducts = $$v;
      },
      expression: "forSomeProducts"
    }
  })], 1)]), _vm.forSomeProducts ? _c('lined-textarea', {
    attrs: {
      "styles": _vm.textareaStyles,
      "limit-number": _vm.availableItems,
      "placeholder": "Введите список SKU, по одному в строке, например:\n58294056\n123975032\n682910567"
    },
    model: {
      value: _vm.skuList,
      callback: function ($$v) {
        _vm.skuList = $$v;
      },
      expression: "skuList"
    }
  }) : _vm._e(), _vm.forSomeProducts ? _c('Import-btn', {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      "tooltip": "Формат файла XLSX или XLS, список SKU по одному в строке в одной колонке",
      "tooltip-classes": "top right"
    },
    on: {
      "imported": _vm.skuListImport
    }
  }) : _vm._e(), _c('div', {
    staticClass: "new-script__step__line"
  }, [_c('span', {
    staticClass: "new-script__step__key"
  }, [_vm._v(" Плюс слова ")]), _c('span', {
    staticClass: "new-script__step__value"
  }, [_c('YesNoSwitchInput', {
    model: {
      value: _vm.hasPlusWords,
      callback: function ($$v) {
        _vm.hasPlusWords = $$v;
      },
      expression: "hasPlusWords"
    }
  })], 1)]), _vm.hasPlusWords ? _c('lined-textarea', {
    attrs: {
      "styles": _vm.textareaStyles,
      "limit-number": _vm.availableItems,
      "placeholder": "Введите список плюс слов, по одному в строке, например:\nвера\nнадежда\nлюбовь"
    },
    model: {
      value: _vm.plusWords,
      callback: function ($$v) {
        _vm.plusWords = $$v;
      },
      expression: "plusWords"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "new-script__step__line"
  }, [_c('span', {
    staticClass: "new-script__step__key"
  }, [_vm._v(" Минус ")]), _c('span', {
    staticClass: "new-script__step__value"
  }, [_c('YesNoSwitchInput', {
    model: {
      value: _vm.hasMinusWords,
      callback: function ($$v) {
        _vm.hasMinusWords = $$v;
      },
      expression: "hasMinusWords"
    }
  })], 1)]), _vm.hasMinusWords ? _c('lined-textarea', {
    attrs: {
      "styles": _vm.textareaStyles,
      "limit-number": _vm.availableItems,
      "placeholder": "Введите список минус слов, по одному в строке, например:\nвера\nнадежда\nлюбовь"
    },
    model: {
      value: _vm.minusWords,
      callback: function ($$v) {
        _vm.minusWords = $$v;
      },
      expression: "minusWords"
    }
  }) : _vm._e()], 1)])]), _c('div', {
    staticClass: "new-script__step"
  }, [_c('h3', {
    staticClass: "new-script__step__title"
  }, [_vm._v("Шаблоны ответов")]), _c('p', {
    staticClass: "new-script__step__text"
  }, [_vm._v(" Вы можете создать до 10 логических блоков ответа, в каждом блоке до 5 вариантов ")]), _c('br'), _c('p', {
    staticClass: "new-script__step__text"
  }, [_vm._v(" Ниже мы подготовили для вас готовый базовый вариант блоков, который подойдет для большинства товаров и категорий. ")]), _c('a', {
    staticClass: "link",
    staticStyle: {
      "margin-top": "10px",
      "display": "block"
    },
    attrs: {
      "href": "https://www.help.salesfinder.ru/reviews_script_management",
      "target": "_blank"
    }
  }, [_vm._v(" Подробная инструкция по настройке шаблонов ")]), _c('TextBlocks', {
    staticStyle: {
      "margin-top": "20px",
      "margin-bottom": "20px"
    },
    attrs: {
      "max": 10
    },
    model: {
      value: _vm.textBlocks,
      callback: function ($$v) {
        _vm.textBlocks = $$v;
      },
      expression: "textBlocks"
    }
  }), _c('button', {
    staticClass: "btn-outline btn-width-auto",
    on: {
      "click": _vm.checkScript
    }
  }, [_vm._v(" Проверить пример ответа ")]), _c('h3', {
    staticClass: "new-script__step__title",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" Доступные переменные ")]), _c('div', {
    staticClass: "new-script__variables"
  }, [_c('p', [_c('b', [_vm._v("{user}")]), _vm._v(" - Добавьте эту переменную в текст, чтоб подставить имя покупателя "), _c('br'), _c('br'), _c('b', [_vm._v("{recommendation}")]), _vm._v(" - Добавьте эту переменную в текст, чтобы вставить рекомендованные SKU (необходимо загрузить связки рекомендаций) ")]), _c('br'), _c('div', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "SalesFinder_Recommendation_Example.xlsx",
      "target": "_blank",
      "download": ""
    }
  }, [_vm._v(" Пример файла рекомендаций ")]), _c('br'), _c('br'), _c('a', {
    staticClass: "link",
    attrs: {
      "href": "https://www.help.salesfinder.ru/reviews_recommendations",
      "target": "_blank"
    }
  }, [_vm._v(" Инструкция по настройке рекомендаций ")])])]), _c('div', {
    staticClass: "recommendations"
  }, [_c('label', [_c('input', {
    ref: "file",
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "accept": ".xls,.xlsx"
    },
    on: {
      "change": _vm.handleFileSelect
    }
  }), _c('a', {
    staticClass: "btn-outline btn-width-auto import__btn"
  }, [_vm._v(" Загрузить связки рекомендаций (XLSX) ")])]), _vm.recommendationsFileName ? _c('span', {
    staticClass: "recommendations-current-file"
  }, [_vm._v(" Активный файл рекомендаций: "), _c('b', {
    on: {
      "click": _vm.exportRecommendations
    }
  }, [_vm._v(" " + _vm._s(_vm.recommendationsFileName) + " ")])]) : _vm._e()]), _c('button', {
    staticClass: "btn-blue mt32 save-btn",
    attrs: {
      "disabled": !_vm.saveEnabled
    },
    on: {
      "click": _vm.saveScpript
    }
  }, [_vm._v(" Сохранить ")])], 1)], 1) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы загружаем данные вашего магазина',
      "image": require('@/assets/images/wireframe.jpg'),
      "text1": 'На период загрузки отчеты пока недоступны, обычно этот процесс занимает 4-8 часов. Попробуйте вернуться позже в этот раздел.',
      "text2": 'Также по завершении загрузки мы отправим оповещение на ваш email'
    }
  })], 1), _c('modal-check', {
    ref: "modalCheck"
  }), _c('modal-recommendations', {
    ref: "modalRecommendations"
  }), _c('modal-check-by-hand-confirm', {
    ref: "modalCheckByHandConfirm"
  }), _c('modal-save-confirm', {
    ref: "modalSaveConfirm"
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
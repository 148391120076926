var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.mode == 'textarea' ? _c('textarea', {
    domProps: {
      "value": _vm.value
    }
  }) : _c('input', {
    domProps: {
      "value": _vm.value
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };
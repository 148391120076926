import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import MultiCheckbox from "@/components/MultiCheckbox.vue";
import TextBlocks from "@/components/TextBlocks.vue";
import YesNoSwitchInput from "@/components/YesNoSwitchInput.vue";
import DataLoading from "@/components/Loading/DataLoading";
import LinedTextarea from "@/components/LinedTextarea.vue";
import ImportBtn from "@/components/Seo/ImportBtn.vue";
import AppSelect from "@/components/AppSelect.vue";
import ReviewManagementMixin from "@/mixins/ReviewManagement";
import HasTokens from "@/components/HasTokensReviewManagement.vue";
import ModalCheck from "@/components/modals/ModalReviewManagementScriptCheck.vue";
import ModalRecommendations from "@/components/modals/ModalReviewManagementScriptRecommendations.vue";
import ModalCheckByHandConfirm from "@/components/modals/ModalReviewManagementScriptCheckByHandConfirm.vue";
import ModalSaveConfirm from "@/components/modals/ModalReviewManagementScriptSaveConfirm.vue";
import { read, utils } from "xlsx";
import ExportJsonExcel from "js-export-excel";
export default {
  mixins: [ReviewManagementMixin],
  meta: {
    title: "Создание сценария"
  },
  data() {
    return {
      token: null,
      name: null,
      skuList: "",
      plusWords: "",
      minusWords: "",
      textareaStyles: {
        height: "300px",
        width: "100%",
        resize: "vertical"
      },
      availableItems: 1000,
      marks: [{
        label: 1,
        value: false
      }, {
        label: 2,
        value: false
      }, {
        label: 3,
        value: false
      }, {
        label: 4,
        value: false
      }, {
        label: 5,
        value: false
      }],
      textBlocks: [{
        label: "Приветствие",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: ""
      }, {
        label: "Благодарность",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: ""
      }, {
        label: "Рекомендация",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: ""
      }, {
        label: "Прощание",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: ""
      }],
      answerOldReviews: false,
      checkingByHand: true,
      onlyForReviewsWithPhoto: false,
      forSomeProducts: false,
      hasPlusWords: false,
      hasMinusWords: false,
      recommendations: [],
      recommendationsFileName: ""
      // tagifyValue: " fasdf",
      // tagifySettigns: {
      //     //  mixTagsInterpolator: ["{{", "}}"],
      //     mode: "mix", // <--  Enable mixed-content
      //     pattern: /@|#/, // <--  Text starting with @ or # (if single, String can be used here)
      //     tagTextProp: "text", // <-- the deonTagsChangefault property (from whitelist item) for the text to be rendered in a tag element.
      //     // Array for initial interpolation, which allows only these tags to be used
      //     whitelist: whitelist,
      //     dropdown: {
      //         enabled: 1,
      //         position: "text", // <-- render the suggestions list next to the typed text ("caret")
      //         mapValueTo: "text", // <-- similar to above "tagTextProp" setting, but for the dropdown items
      //         highligModalCheckhtFirst: true, // automatically highlights first sugegstion item in the dropdown
      //     },
      //     callbacks: {
      //         input: this.onTagInput,
      //     },
      // },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.loadScript();
    }
  },
  methods: {
    skuListImport(list) {
      list.forEach(row => {
        this.skuList += row + "\n";
      });
    },
    async checkScript() {
      const data = await this.$store.dispatch("reviewManagement/checkScript", this.template);
      this.$refs.modalCheck.open({
        text: data.answer
      });
    },
    checkingByHandWillChange(data) {
      if (data.value) {
        this.$refs.modalCheckByHandConfirm.open({
          onComplete: () => {
            data.confirm();
          }
        });
      } else {
        data.confirm();
      }
    },
    // onTagInput(e) {
    //     //console.log(e.detail);
    //     this.tagifyValue = e.detail.textContent;
    // },
    // tagsCallback(e) {
    //     //console.log(e.type, e.detail);
    // },
    async loadScript() {
      const data = await this.$store.dispatch("reviewManagement/getScript", this.$route.params.id);
      this.name = data.name;
      this.token = this.select_tokens_items.find(item => item.title === data.token);
      this.marks = this.marks.map(item => {
        return {
          ...item,
          value: data.valuations.includes(item.label)
        };
      });
      this.answerOldReviews = !!data.support_archived;
      this.checkingByHand = !!data.approval_required;
      this.onlyForReviewsWithPhoto = !!data.photos_required;
      this.forSomeProducts = !!data.skus;
      if (data.skus) {
        data.skus.forEach(item => {
          this.skuList += `${item}\n`;
        });
      }
      this.hasPlusWords = !!data.plus_words;
      if (data.plus_words) {
        data.plus_words.forEach(item => {
          this.plusWords += `${item}\n`;
        });
      }
      this.hasMinusWords = !!data.minus_words;
      if (data.minus_words) {
        data.minus_words.forEach(item => {
          this.minusWords += `${item}\n`;
        });
      }
      this.textBlocks = data.template.map(item => {
        const values = {};
        item.options.forEach((value, index) => {
          values[`value${index + 1}`] = value;
        });
        return {
          label: item.name,
          ...values
        };
      });
      this.recommendations = data.recommendations;
      if (data.recommendations) {
        this.recommendationsFileName = "recommendations.xlsx";
      }
    },
    async saveScpript() {
      const marks = [];
      this.marks.forEach(item => {
        if (item.value) {
          marks.push(item.label);
        }
      });
      const body = {
        token_id: this.token.id,
        name: this.name,
        valuations: marks,
        support_archived: this.answerOldReviews,
        template: this.template,
        approval_required: this.checkingByHand,
        photos_required: this.onlyForReviewsWithPhoto,
        skus: this.skuList ? this.skuList.split("\n").filter(item => item != "").map(item => Number(item)) : [],
        plus_words: this.plusWords ? this.plusWords.split("\n").filter(item => item != "") : [],
        minus_words: this.minusWords ? this.minusWords.split("\n").filter(item => item != "") : [],
        recommendations: this.recommendations
      };
      try {
        if (!this.$route.params.id) {
          await this.$store.dispatch("reviewManagement/addScript", body);
          this.$router.push({
            name: "ReviewManagementScripts"
          });
        } else {
          this.$refs.modalSaveConfirm.open({
            onComplete: async () => {
              await this.$store.dispatch("reviewManagement/updateScript", {
                id: this.$route.params.id,
                body
              });
              this.$router.push({
                name: "ReviewManagementScripts"
              });
            }
          });
        }
      } catch (e) {
        //console.error(e);
        this.$router.push({
          name: "ReviewManagementScripts"
        });
      }
    },
    handleFileSelect(event) {
      const files = event.target.files;
      const split = event.target.value.split("\\");
      this.recommendationsFileName = split[split.length - 1];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = read(data, {
          type: "binary"
        });
        workbook.SheetNames.forEach(sheetName => {
          let XL_row_object = utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          this.recommendations = XL_row_object.map(item => {
            const values = Object.values(item);
            return {
              sku: values.splice(0, 1)[0],
              options: values
            };
          });
        });
      };
      reader.onerror = function (ex) {// eslint-disable-line
        //console.log(ex);
      };
      reader.readAsBinaryString(files[0]);
      this.$refs.file.value = "";
      this.$refs.modalRecommendations.open();
    },
    exportRecommendations() {
      var _this$recommendations;
      const sheetHeader = ["исходный SKU"];
      const columnWidths = [7];
      const columnsCount = (_this$recommendations = this.recommendations[0]) === null || _this$recommendations === void 0 ? void 0 : _this$recommendations.options.length;
      for (let i = 0; i < columnsCount; i++) {
        sheetHeader.push(`рекомендация ${i + 1}`);
        columnWidths.push(7);
      }
      const responseData = {
        fileName: this.recommendationsFileName.split(".")[0],
        datas: [{
          sheetHeader,
          columnWidths,
          sheetData: this.recommendations.map(item => {
            const result = {
              "исходный SKU": String(item.sku)
            };
            item.options.forEach((option, index) => {
              result[`рекомендация ${index + 1}`] = String(option);
            });
            return result;
          })
        }]
      };
      let toExcel = new ExportJsonExcel(responseData);
      toExcel.saveExcel();
    }
  },
  computed: {
    template() {
      return this.textBlocks.filter(item => Object.values(item).some((el, index) => !!el && index > 0)).map(item => {
        const options = [];
        const regex = /\[\[\{"value":"(.*?)","prefix":"@(.*?)"\}\]\]/g;
        Object.values(item).forEach((value, index) => {
          if (value && index > 0) {
            options.push(value.replace(regex, "{$1}"));
          }
        });
        return {
          name: item.label,
          options
        };
      });
    },
    saveEnabled() {
      return this.template.some(item => item.options.length) && this.marks.some(item => item.value) && this.name;
    }
  },
  watch: {
    select_tokens_items(val) {
      this.token = val[0];
    }
  },
  components: {
    MainTitle,
    ReportPage,
    HasTokens,
    DataLoading,
    LinedTextarea,
    ImportBtn,
    MultiCheckbox,
    TextBlocks,
    YesNoSwitchInput,
    AppSelect,
    ModalCheck,
    ModalRecommendations,
    ModalCheckByHandConfirm,
    ModalSaveConfirm
    // Tagify,
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "multi-checkbox"
  }, _vm._l(_vm.data, function (item, index) {
    return _c('label', {
      key: index,
      staticClass: "multi-checkbox__item"
    }, [_c('input', {
      staticClass: "multi-checkbox__item__input",
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": item.value
      },
      on: {
        "change": function ($event) {
          return _vm.change(index);
        }
      }
    }), _c('span', {
      staticClass: "multi-checkbox__item__label"
    }, [_vm._v(_vm._s(item.label))])]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import "core-js/modules/es.array.push.js";
import Tagify from "@/components/Tagify/Tagify.vue";
export default {
  components: {
    Tagify
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    max: {
      type: Number
    }
  },
  data() {
    let whitelist = ["user", "recommendation"];
    return {
      data: this.value,
      tagifySettigns: {
        //  mixTagsInterpolator: ["{{", "}}"],
        mode: "mix",
        // <--  Enable mixed-content
        // pattern: /@|#/, // <--  Text starting with @ or # (if single, String can be used here)
        pattern: "@",
        tagTextProp: "text",
        // <-- the deonTagsChangefault property (from whitelist item) for the text to be rendered in a tag element.
        // Array for initial interpolation, which allows only these tags to be used
        whitelist: whitelist,
        enforceWhitelist: true,
        editTags: false,
        dropdown: {
          enabled: 1,
          position: "text",
          // <-- render the suggestions list next to the typed text ("caret")
          mapValueTo: "text",
          // <-- similar to above "tagTextProp" setting, but for the dropdown items
          highlightFirst: true // automatically highlights first sugegstion item in the dropdown
        },
        callbacks: {
          input: this.onTagCallback,
          blur: this.onTagCallback,
          add: this.onTagCallback,
          remove: this.onTagCallback,
          change: this.onTagCallback
        }
      }
    };
  },
  methods: {
    // input(val, index) {
    //     this.data[index].value = val;
    //     this.$emit("input", this.data);
    // },
    add() {
      if (this.data.length < this.max) {
        this.data.push({
          label: "Новый блок",
          value: ""
        });
      }
    },
    remove(index) {
      this.data.splice(index, 1);
    },
    onTagCallback(e) {
      const id = e.detail.tagify.DOM.originalInput.id;
      const index = Number(id.split("-")[1]);
      const number = Number(id.split("-")[2]);

      // const regex = /\[\[\{"value":"(.*?)","prefix":"@(.*?)"\}\]\]/g;
      this.data[index][`value${number}`] = e.detail.tagify.DOM.originalInput.tagifyValue;
      // e.detail.tagify.DOM.originalInput.tagifyValue.replace(
      //     regex,
      //     "{$1}"
      // );
    }
  },
  watch: {
    value: {
      deep: true,
      handler(val, oldVal) {
        if (oldVal !== val) {
          this.data = val;
        }
      }
    },
    data(val, oldVal) {
      if (oldVal !== val) {
        this.$emit("input", val);
      }
    }
  }
};
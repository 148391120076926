var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Пример созданного ответа",
      "width": 420,
      "actions": _vm.actions
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "yes-no-switch-input"
  }, [_c('span', {
    staticClass: "yes-no-switch-input__text yes-no-switch-input__no",
    class: {
      active: !_vm.data
    },
    on: {
      "click": function ($event) {
        return _vm.confirmChangeCheck(false);
      }
    }
  }, [_vm._v(" Нет ")]), _c('SwitchInput', {
    staticClass: "yes-no-switch-input__switch",
    attrs: {
      "confirmationOnTurnOff": _vm.confirmationOnTurnOff
    },
    on: {
      "willChange": _vm.willChange
    },
    model: {
      value: _vm.data,
      callback: function ($$v) {
        _vm.data = $$v;
      },
      expression: "data"
    }
  }), _c('span', {
    staticClass: "yes-no-switch-input__text yes-no-switch-input__yes",
    class: {
      active: _vm.data
    },
    on: {
      "click": function ($event) {
        return _vm.confirmChangeCheck(true);
      }
    }
  }, [_vm._v(" Да ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text-blocks"
  }, [_vm._l(_vm.data, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "text-blocks__item"
    }, [_c('img', {
      staticClass: "text-blocks__item-close",
      attrs: {
        "src": require('@/assets/images/icons/close.svg'),
        "alt": "Remove"
      },
      on: {
        "click": function ($event) {
          return _vm.remove(index);
        }
      }
    }), _c('div', {
      staticClass: "text-blocks__title-line"
    }, [_c('span', {
      staticClass: "text-blocks__title-number"
    }, [_vm._v(_vm._s(index + 1))]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.label,
        expression: "item.label"
      }],
      staticClass: "text-blocks__title",
      attrs: {
        "maxlength": "25",
        "type": "text",
        "placeholder": "Название блока"
      },
      domProps: {
        "value": item.label
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "label", $event.target.value);
        }
      }
    })]), _c('div', {
      staticClass: "text-blocks__item__input"
    }, _vm._l(5, function (input) {
      return _c('Tagify', {
        key: input,
        staticClass: "tagify",
        attrs: {
          "mode": 'textarea',
          "id": `tagify-${index}-${input}`,
          "settings": _vm.tagifySettigns,
          "value": item[`value${input}`]
        }
      });
    }), 1)]);
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.data.length < _vm.max,
      expression: "data.length < max"
    }],
    staticClass: "text-blocks__item",
    on: {
      "click": _vm.add
    }
  }, [_c('img', {
    staticClass: "text-blocks__item-icon",
    attrs: {
      "src": require('@/assets/images/icons/plus-circled.svg'),
      "alt": "Plus"
    }
  })])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };